export default {
  couponViewItem: [
    {
      prop: 'name'
    }, 
    {
      prop: 'use_type'
    },
    {
      prop: 'price',
      unit: 'S$'
    },
    {
      prop: 'count'
    },
    {
      prop: 'valid_days'
    },
    {
      prop: 'expire_date',
      date: true,
      format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
    },
    {
      prop: 'coupon_code'
    }
  ],
  orderViewItem: [
    // {
    // prop: 'status',
    // back: true
    // }, 
  {
    prop: 'client'
  }, {
    prop: 'name'
  },  {
    prop: 'payment_channel'
  },{
    prop: 'created_at'
    // format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'paid_at'
    //date: true,
    //format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }],
  CustomerViewItem: [{
    prop: 'user_id'
  }, {
    prop: 'nickname'
  }, {
    prop: 'phone_number'
  }],
  tableDataViewItem: [{
    prop: 'name',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'user_type',
    label: '',
    Object: 'user_type'
    // width: '100'
  }, {
    prop: 'quantity',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'coupon_code',
    label: '',
    Object: 'value'
    // width: '40'
  }]
}
