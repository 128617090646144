<template>
  <div class="couponOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.orderInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table
                tableType="object"
                :custSpan="6"
                :data="custName.order_information"
                :dataItem="orderViewItem"
                @forwardToPage="forwardToPage"
              />
            </el-card>
            </el-col>
          </el-row>
          <el-row v-if="custName.coupon_information">
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.couponInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table
                tableType="object"
                :custSpan="6"
                :data="custName.coupon_information"
                :dataItem="couponViewItem"
                @forwardToPage="forwardToPage"
              />
            </el-card>
            </el-col>
          </el-row>
          <el-row class="margintop20" :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.custInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table
                  tableType="object"
                  :custSpan="8"
                  :data="custName.customer_information"
                  :dataItem="CustomerViewItem"
                  @forwardToPage="forwardToPage"
                />
              </el-card>
            </el-col>
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.amountInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table
                  tableType="array"
                  :custSpan="24"
                  :dataItem="custName.price_infos"
                />
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.order.items')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable
                  :autoWidth="true"
                  :tableDataItem="tableDataViewItem"
                  :operateVisible="false"
                  :tableData="custName.coupon_snapshots"
                  :operationColWidth="60"
                />
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
    <el-dialog
      :title="$t('form.order.romoveProduct')"
      :visible.sync="dialog.delOderItem.visible"
      width="500px">
      <div class="del-oder-item-dialog-body">
        <div class="heder">
          <el-avatar class="avatar" shape="square" :size="80" fit="cover" :src="dialog.delOderItem.data && dialog.delOderItem.data.image.value"></el-avatar>
          <div class="info-box">
            <div class="title">{{ $t('form.order.productName') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.shop_item.value.clickables[0].name }}</div>
            <div class="title">{{ $t('form.order.sku') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.sku.value }}</div>
          </div>
        </div>
        <div class="notice">{{ $t('form.order.removeProducNotice') }}</div>
        <el-form ref="delOderItemDialogForm" :model="dialog.delOderItem.formData" :rules="dialog.delOderItem.formDataRules" label-width="80px" label-position="left">
          <el-form-item :label="$t('form.order.currentCount')">{{ dialog.delOderItem.data && dialog.delOderItem.data.count.value }}</el-form-item>
          <el-form-item :label="$t('form.order.romveCount')" prop="quantity">
            <el-input-number v-model="dialog.delOderItem.formData.quantity" :min="1" :max="dialog.delOderItem.data && dialog.delOderItem.data.count.value"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="handleDelOderItemDialogCancelClick">{{ $t('button.cancelbig') }}</el-button>
        <el-button type="primary" @click="handleDelOderItemDialogOkClick">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getCouponBundleOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import request from '@/utils/request'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {
        show: true
      },
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      orderViewItem: jsonData.orderViewItem,
      CustomerViewItem: jsonData.CustomerViewItem,
      amountViewItem: jsonData.amountViewItem,
      couponViewItem: jsonData.couponViewItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      historyTableItem: history.historyTableItem,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getCouponBundleOrderDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.coupon_bundle_payment
        Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: this.custName.order_number ? this.custName.order_number : 0 })
        this.custName.price_infos = []
      }
      
      this.$store.state.app.loading.close()
      this.getDetail()
    },
    getDetail () {
      if (this.custName && this.custName.coupon_snapshots && this.custName.coupon_snapshots.length > 0) {
        Object.keys(this.custName.coupon_snapshots[0]).forEach(item => {
          const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
          if (index !== -1) this.tableDataViewItem[index].label = this.custName.coupon_snapshots[0][item].name
        })
      }
      if (this.custName && this.custName.price_information) {
        if ( Object.prototype.toString.call(this.custName.price_information) === '[object Object]') {
          for (let k in this.custName.price_information) {
            let str = this.custName.price_information[k].value
            if(!(str + '').includes('S$')) {
              if(!(str + '').includes('.')) {
                let n = Number(str)
                if (n < 0) {
                  this.custName.price_information[k].value = '-' + 'S$' + ((Math.abs(n) / 100).toFixed(2))
                } else {
                  this.custName.price_information[k].value = 'S$' + ((n / 100).toFixed(2))
                }
              } else {
                this.custName.price_information[k].value = 'S$' + str
              }
              
            }
            this.custName.price_infos.push(this.custName.price_information[k])
          }
        } else {
          this.custName.price_infos = this.custName.price_information
        }
      }
      //  获取历史操作
      getHistoryList({ item_type: 'CouponBundlePayment', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    handleCusClick (data) {
   
    },
    async dialogSearch () {
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data, extraType) {
      if (extraType === 'del') {
        if (data.count.value > 0) {
          this.dialog.delOderItem.formData.quantity = 1
          this.dialog.delOderItem.data = data
          this.dialog.delOderItem.visible = true
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('form.order.productCountLessThanOne')
          })
        }
      }
    },
    handleDelOderItemDialogCancelClick () {
      this.dialog.delOderItem.visible = false
    },
    handleDelOderItemDialogOkClick () {
      this.$refs.delOderItemDialogForm.validate((valid) => {
        if (valid) {
          const data = this.dialog.delOderItem.data
          const requestOption = data.member_actions.custom_actions[0]
          this.$store.state.app.loading = this.$loading(loading)
          request({
            method: requestOption.method,
            url: `/cms/${requestOption.path}`,
            data: {
              [requestOption.extra_params[0].key]: this.dialog.delOderItem.formData.quantity
            }
          }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.operateSuccess')
            })
            this.dialog.delOderItem.visible = false
            this.init()
          }).catch(() => this.$store.state.app.loading.close())
        }
      })
    },
    forwardToPage (data) {
      if (data.model_name && data.model_name === 'host' && data.id) {
        this.$router.push({
          name: 'buddyManagerBuddyDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'user' && data.id) {
        this.$router.push({
          name: 'memberDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'delivery_order' && data.id) {
        this.$router.push({
          name: 'ordersManagerDeliveryOrdersDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
.couponOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
